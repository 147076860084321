import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise"; // Import Ag-Grid Enterprise features
import "ag-grid-enterprise/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-quartz.css";
import "../AgTable.scss";
// import "../AgTheme.css"
import { Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { handleAgCellDataFormat, handleCellRenderer } from "../AgUtility";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import PDFViewerTool from "../HelperTools/PDFViewerTool";
import PDFViewerToolV2 from "../HelperTools/PDFViewerTool/pdfViewerV2";

const detailCellRendererParams = {
  detailGridOptions: {
    columnDefs: [{ field: "price", headerName: "Price" }],
  },
  getDetailRowData: (params) => {
    params.successCallback(params.data.details);
  },
};

const AgTableClient = forwardRef(function (props, ref) {
  // Column definitions
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const gridRef = useRef();
  const processColumnDefs = (columnDefs) => {
    return columnDefs.map((obj) => ({
      ...obj, // Spread the original object
      ...(obj.formatType ? handleCellRenderer() : {}), // Add the new key-value pair
      ...(obj.editRow
        ? {
            editFunction: (params, status, colKey) =>
              handleCellEditing(params, status, colKey),
          }
        : {}),
    }));
  };

  useEffect(() => {
    if (gridApi && gridColumnApi) {
      props.autoResize && gridApi.sizeColumnsToFit();
    }
  }, [props.columnDefs, gridApi, gridColumnApi]);

  const onGridReady = useCallback(async (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);

    params.api.autoSizeAllColumns();
  }, []);

  const onCellValueChanged = useCallback((event) => {}, []);

  const onRowValueChanged = useCallback((event) => {
    props.onRowSaved && props.onRowSaved(event.data);
  }, []);

  const handleCellEditing = (params, editingOn, colKey) => {
    if (!editingOn) {
      onBtStopEditing();
    } else {
      onBtStartEditing(params, colKey);
    }
  };
  const onBtStartEditing = useCallback((params, colKey) => {
    gridRef.current.api.setFocusedCell(params?.rowIndex, colKey);
    gridRef.current.api.startEditingCell({
      rowIndex: params?.rowIndex,
      colKey: colKey,
    });
  }, []);

  const onTableSearch = (event) => {
    gridRef.current && gridRef.current?.api?.setQuickFilter(event.target.value);
  };

  const onBtStopEditing = useCallback(() => {
    gridRef.current.api.stopEditing();
  }, []);

  const exportDataToExcel = useCallback(() => {
    gridRef.current.api.exportDataAsExcel(); // Access grid API from the ref
  }, [gridRef]);

  const exportDataToCSV = useCallback(() => {
    const params = {
      suppressQuotes: true, // Suppress quotes around cell values
      processHeaderCallback: (params) => {
        // Convert column headers to lowercase
        return params.column.getColDef().headerName.toLowerCase();
      },
      processCellCallback: (params) => {
        // Convert cell values to lowercase if they are strings

        return params.value;
      },
    };
    gridRef.current.api.exportDataAsCsv(params);
  }, [gridRef]);

  React.useImperativeHandle(ref, () => ({
    exportDataToExcel,
    exportDataToCSV,
    exportJSONData,
    getAllColumns,
    onTableSearch,
    setFilterModel: (filterModel) => {
      gridRef.current.api.setFilterModel(filterModel);
    },
    setSortModel: (sortModel) => {
      if (gridRef.current) {
        gridRef.current.api.setSortModel(sortModel);
      }
    },
    applyColumnState: (sortModel) => {
      if (gridRef.current) {
        gridRef.current.api.applyColumnState({
          state: sortModel.map((s) => ({ colId: s.colId, sort: s.sort })),
        });
      }
    },
    setGroupModel: (groupModel) => {
      gridRef.current.columnApi.setRowGroupColumns(
        groupModel.map((group) => group.colId || group.field)
      );
    },
  }));

  const exportJSONData = () => {
    if (gridApi) {
      const rowData = [];
      gridApi.forEachNode((node) => rowData.push(node.data));

      const transformedData = rowData.map((row) => {
        const obj = {};
        props.columnDefs.forEach((column) => {
          obj[column.field] = row[column.field];
        });
        return obj;
      });

      return transformedData;
    }
  };

  const handleColumnVisibilityChange = () => {
    const columnState = gridApi.getColumnState();
    const groupedColumn = columnState.find((column) => column.hide);
    props.onColGroup && props.onColGroup(groupedColumn);
    // setGroupedColumn(groupedColumn ? groupedColumn.colId : null);
  };

  const getAllColumns = () => {
    console.log("all columsn", gridRef.current);
    // const allColumns = gridRef.current.api.getAllColumns();
    // console.log(allColumns);
  };

  const statusBar = {
    statusPanels: [
      {
        statusPanel: "agTotalAndFilteredRowCountComponent",
        align: "left",
      },
      {
        statusPanel: "agTotalRowCountComponent",
        align: "center",
      },
      {
        statusPanel: "agFilteredRowCountComponent",
        align: "center",
      },
      {
        statusPanel: "agSelectedRowCountComponent",
        align: "right",
      },
      {
        statusPanel: "agAggregationComponent",
        align: "right",
      },
    ],
  };

  const sideBar = useMemo(
    () => ({
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
        },
        {
          id: "pdfRenderer",
          labelDefault: "PDF View",
          labelKey: "pdfRenderer",
          iconKey: "pdf-file",
          width: 500,
          toolPanel: props.pdfV2 ? PDFViewerToolV2 : PDFViewerTool,
          toolPanelParams: {
            dataKey: props.pdfDataKey,
            pdfData: props.pdfData,
          },
        },
      ],
    }),
    [props.pdfData]
  );

  return (
    <div className="AgTable">
      {props.showExport || props.leftTopActions || props.rightTopActions ? (
        <div className="TableActionContainer">
          <div className="LeftContainer">{props.leftTopActions}</div>
          <div className="RightContainer">
            {props.rightTopActions}
            <div className="ActionButton">
              {props.showExport ? (
                <Button
                  type="primary"
                  icon={<DownloadOutlined />}
                  shape="round"
                  size="small"
                  style={{
                    fontSize: 12,
                  }}
                  id="agTableExportButton"
                  onClick={exportDataToExcel}
                >
                  Export
                </Button>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
      <div className="ag-theme-alpine">
        <AgGridReact
          rowData={props.rowData}
          columnDefs={processColumnDefs(props.columnDefs) || []}
          // defaultColDef={processColumnDefs(props.columnDefs) || []}
          pagination={!props.footer ? false : true}
          paginationPageSize={1000}
          rowSelection="multiple"
          suppressCellSelection={true}
          resizable={true}
          enablePivot={true}
          ref={gridRef}
          enableSorting={true}
          enableFilter={true}
          statusBar={props.showStatusBar && statusBar}
          groupMultiAutoColumn={true}
          rowGroupPanelShow={props.groupPanel ? "always" : "never"}
          pivotPanelShow="always"
          onGridReady={onGridReady}
          grandTotalRow={props.grandTotalRow}
          // pinnedBottomRowData={props.pinnedBottomRowData}
          pivotColumnGroupTotals="before"
          // pivotColumns={["Vendor Name"]}
          // autoGroupColumnDef={["Vendor Name"]}
          // onColumnGrouped={onColumnGrouped}
          //function related to cell/row edit change
          onCellValueChanged={onCellValueChanged}
          onRowValueChanged={onRowValueChanged}
          //function related to cell/row edit change
          editType={"fullRow"}
          onColumnVisible={handleColumnVisibilityChange}
          suppressClickEdit={true}
          autoSizeStrategy={
            props.autoResize
              ? {
                  type: "fitGridWidth",
                  defaultMinWidth: 100,
                }
              : {}
          }
          defaultColDef={{
            resizable: props.autoResize,
          }}
          sideBar={props.hideToolbar ? {} : sideBar}
          autoGroupColumnDef={{
            headerName: "Group",
            cellRenderer: "agGroupCellRenderer",
            cellRendererParams: {
              innerRenderer: (params) => {
                // Find the original column definition based on the field name
                const originalColumn = props.columnDefs.find(
                  (colDef) => colDef.field === params.node.field
                );
                console.log("mainGroupRow:", params);
                return (
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                      justifyItems: "center",
                    }}
                  >
                    {handleAgCellDataFormat(
                      originalColumn?.formatType || "",
                      params.value,
                      originalColumn?.formatProps || "",
                      params.data || {}
                    ) || <strong>Total</strong>}
                  </div>
                );
              },
            },
          }}
        />
      </div>
    </div>
  );
});
export default AgTableClient;
