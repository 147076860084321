import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import "./SearchInput.scss";
import { colorPicker } from "app/utils/color.helper";

export default function SearchInput(props: any) {
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  // Debounce effect: updates debouncedSearchTerm after a delay
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 300); // 300ms debounce delay

    // Cleanup timeout if user types within the delay
    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  // Effect to trigger search when debouncedSearchTerm changes
  useEffect(() => {
    // if (debouncedSearchTerm) {
    props.onSearch && props.onSearch(debouncedSearchTerm); // Assuming you pass onSearch function in props
  }, [debouncedSearchTerm, props]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  return (
    <Input
      placeholder={props.placeholder || "Search member"}
      prefix={
        <SearchOutlined
          className="site-form-item-icon"
          style={{
            color: colorPicker(props.colored ? "primary.700" : "neutral.700"),
          }}
        />
      }
      style={{
        borderRadius: props.rounded ? 24 : 6,
        width: "100%",
        height: props.height || "auto",
        background: props.colored ? "#0A83941F" : "",
        border: props.colored ? "none" : "",
      }}
      className="SearchInput"
      size={props.size || "small"}
      value={searchTerm}
      onChange={handleInputChange}
    />
  );
}
