import { atom } from "recoil";

export const userInfo: any = atom({
  key: "userInfo",
  default: {},
});

export const uploadInfo: any = atom({
  key: "uploadInfo",
  default: null,
});

export const onboardInfo: any = atom({
  key: "onboardInfo",
  default: {},
});

export const tabViewStates: any = atom({
  key: "tabViewStates",
  default: {
    dashboard: [],
    gst_recon: [
      {
        title: "GST Recon",
        id: 1,
      },
    ],
    air_recon: [
      {
        title: "Air Recon",
        id: 1,
      },
    ],
    reports: [
      {
        title: "Reports",
        id: 1,
        type: "TABLE",
      },
    ],
    invoices: [
      {
        title: "Invoice Explorer",
        id: 1,
        type: "VIEW",
      },
    ],
  },
});
