import type { UploadProps } from "antd";
import { Upload } from "antd";
import { colorPicker } from "app/utils/color.helper";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import Typography from "../Typography";
import "./Uploader.scss";
const { Dragger } = Upload;

export default function Uploader(props: any) {
  const beforeUploadHandler = () => {
    return false; // Allow default action
  };

  const draggerProps: UploadProps = {
    name: "file",
    multiple: true,
    beforeUpload: beforeUploadHandler,
    onChange(info) {
      const { status } = info.file;
      console.log("new file", info.file);
      props.onSelect && props.onSelect(info.file);
    },
    onDrop(e) {},
  };

  return (
    <Dragger
      {...draggerProps}
      style={{
        borderColor: props.colored
          ? "var(--base-color-primary)"
          : colorPicker("neutral.500"),
        backgroundColor: props.colored ? "#0A83940A" : "white",
        padding: "38px 0",
        cursor: "pointer",
        height: 260,
      }}
      showUploadList={false}
    >
      <p className="ant-upload-drag-icon">
        <CloudUploadOutlinedIcon
          style={{
            fontSize: 34,
            color: colorPicker("primary.700"),
          }}
          fontSize="large"
        />
      </p>
      <Typography
        style={{
          textAlign: "center",
          color: colorPicker("neutral.800"),
          display: "flex",
          justifyContent: "center",
        }}
      >
        Drag and Drop or choose file to upload
      </Typography>
      <p
        className="ant-upload-hint"
        style={{ color: colorPicker("neutral.950"), fontSize: 12 }}
      >
        {props.fileType || "CSV only"}
      </p>
    </Dragger>
  );
}
