import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise"; // Import Ag-Grid Enterprise features
import "ag-grid-enterprise/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-alpine.css";
import "../AgTable.scss";
// import "../AgTheme.css";

import { Button, Empty, Tag } from "antd";
import {
  DownloadOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
} from "@ant-design/icons";
import { handleAgCellDataFormat, handleCellRenderer } from "../AgServerUtility";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import PDFViewerTool from "../HelperTools/PDFViewerTool";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import Loader from "app/shared/Loader";
const AgTableServer = forwardRef((props, ref) => {
  const [gridApi, setGridApi] = useState(null);
  const [columnDefs, setColumnDefs] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [userDetails, _] = useRecoilState(userInfo);
  const [isLoading, setLoading] = useState(false);
  const [grandTotalRow, setGrandTotalRow] = useState(null);

  const gridRef = useRef();

  const processColumnDefs = (columnDefs) => {
    return columnDefs.map((obj) => ({
      ...obj, // Spread the original object
      ...(obj.formatType ? handleCellRenderer() : {}), // Add the new key-value pair
    }));
  };

  const fetchServerSide = useCallback(async () => {
    // Update the grid data

    gridApi.setServerSideDatasource({
      getRows: function (params) {
        // Use the fetched data to update the grid
        fetch(props.endpoint || "", {
          method: "POST",
          body: JSON.stringify({
            ...params.request,
            ...props.serverPayload,
            useWorkspace: props.useWorkspace,
          }),
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
            workspaceId: localStorage.getItem("currentWorkspace"),
            platform: "mmt",
          },
        })
          .then((httpResponse) => httpResponse.json())
          .then((response) => {
            params.success({
              rowData: response.data.rows,
              rowCount: response.data.lastRow,
              ...(response.data.pivotResultFields &&
              response.data.pivotResultFields.length > 0
                ? { pivotResultFields: response.data.pivotResultFields }
                : {}),
            });

            // setGrandTotalRow(response.data.grandTotalRow);
            setRowData(response.data.rows);
            // handleColumnRowGroupChanged(params);
          })
          .catch((error) => {
            console.error(error);
            params.fail();
          });
        setLoading(false);
      },
    });
  }, [gridApi]);

  useEffect(() => {
    if (gridApi) {
      fetchServerSide();
    }
  }, [fetchServerSide, gridApi, userDetails.currentWorkspace]);

  const onGridReady = useCallback(async (params) => {
    const columnApi = params.columnApi;

    setGridApi(params.api);
    // params.api.setServerSideDatasource(datasource);
    setColumnDefs(processColumnDefs(props.columnDefs));
  }, []);

  const exportDataToExcel = useCallback(() => {
    gridRef.current.api.exportDataAsExcel(); // Access grid API from the ref
  }, [gridRef]);

  React.useImperativeHandle(ref, () => ({
    exportDataToExcel,
  }));

  const statusBar = {
    statusPanels: [
      {
        statusPanel: "agTotalAndFilteredRowCountComponent",
        align: "left",
      },
      {
        statusPanel: "agTotalRowCountComponent",
        align: "center",
      },
      {
        statusPanel: "agFilteredRowCountComponent",
        align: "center",
      },
      {
        statusPanel: "agSelectedRowCountComponent",
        align: "right",
      },
      {
        statusPanel: "agAggregationComponent",
        align: "right",
      },
    ],
  };

  return (
    <div className="AgTable">
      {/* <div className="TableActionContainer">
        <div className="LeftContainer">{props.leftTopActions}</div>
        <div className="RightContainer">
          {props.rightTopActions}
          <div className="ActionButton">
            <Button
              type="primary"
              icon={<DownloadOutlined />}
              shape="round"
              size="small"
              style={{ fontSize: 12 }}
            >
              Export
            </Button>
          </div>
        </div>
      </div> */}
      <div className="ag-theme-alpine">
        <AgGridReact
          rowModelType="serverSide"
          columnDefs={columnDefs || []}
          pagination={props.pagination}
          paginationPageSize={100}
          rowSelection="multiple"
          suppressCellSelection={true}
          cacheBlockSize={10}
          resizable={true}
          enablePivot={true}
          enableSorting={true}
          // pivotMode={true}
          enableFilter={true}
          floatingFilter
          groupMultiAutoColumn={true}
          rowGroupPanelShow="always"
          pivotPanelShow="always"
          pivotColumnGroupTotals="before"
          onGridReady={onGridReady}
          suppressAggFuncInHeader={true}
          ref={gridRef}
          statusBar={statusBar}
          // grandTotalRow={"bottom"}
          // serverSidePivotResultFieldSeparator="_"
          sideBar={{
            toolPanels: [
              {
                id: "columns",
                labelDefault: "Columns",
                labelKey: "columns",
                iconKey: "columns",
                toolPanel: "agColumnsToolPanel",
              },
              {
                id: "filters",
                labelDefault: "Filters",
                labelKey: "filters",
                iconKey: "filter",
                toolPanel: "agFiltersToolPanel",
              },
              // {
              //   id: "pdfRenderer",
              //   labelDefault: "PDF View",
              //   labelKey: "pdfRenderer",
              //   iconKey: "pdf-file",
              //   width: 500,
              //   toolPanel: PDFViewerTool,
              // },
            ],
          }}
          // pinnedTopRowData={[]}
          // pinnedBottomRowData={grandTotalRow ? [grandTotalRow] : []}
          pinnedTopRowData={grandTotalRow ? [grandTotalRow] : []}
          autoGroupColumnDef={{
            headerName: "Group",
            cellRenderer: "agGroupCellRenderer",
            field: "groupedColumn",
            cellRendererParams: {
              innerRenderer: (params) => {
                console.log("grouped params:", params);
                // Find the original column definition based on the field name
                const originalColumn = columnDefs.find(
                  (colDef) => colDef.field === params.node.field
                );
                return (
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                      justifyItems: "center",
                    }}
                  >
                    {handleAgCellDataFormat(
                      originalColumn?.formatType || "",
                      params.value,
                      originalColumn?.formatProps || "",
                      params.data || {}
                    )}{" "}
                    {params?.data.count
                      ? " (" + params?.data.count + ")"
                      : null}
                  </div>
                );
              },
            },
          }}
        />
      </div>
    </div>
  );
});

export default AgTableServer;
