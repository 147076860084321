import { Button, Checkbox, Divider, Form, message, Tag } from "antd";
import { apiGet, apiPost } from "app/services/apiServices";
import TextField from "app/shared/TextField";
import Typography from "app/shared/Typography";
import { useState } from "react";
import AppLogo from "static/images/Logo.svg";
import { ArrowRightOutlined, FileOutlined } from "@ant-design/icons";
import { colorPicker } from "app/utils/color.helper";
import "../../AirlineCredentialFlow.scss";
import { useNavigate } from "react-router-dom";

type FieldType = {
  email?: string;
  password?: string;
};

export default function AirlineInviteQuestion(props: any) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(1);
  const navigate = useNavigate();
  return (
    <div className="AirlineStart">
      <Tag color="cyan">Airline Integration</Tag>
      <Typography variant="h5" style={{ marginTop: 12 }}>
        Don't have credential?
      </Typography>

      <div className="QuestionareContainer" style={{ marginTop: 48 }}>
        <div
          className={`QuestionBox ${
            selectedQuestion === 1 ? "--selected" : ""
          }`}
          onClick={() => {
            setSelectedQuestion(1);
            navigate("/credential/airline/question/invite/user");
          }}
        >
          <div className="LeftQuestion">
            <div className="IconBox">
              <FileOutlined style={{ color: colorPicker("primary.700") }} />
            </div>
            <div className="QuestionMeta">
              <Typography
                variant="h6"
                style={{ color: colorPicker("primary.800") }}
                weight={500}
              >
                Invite user
              </Typography>
              <Typography
                variant="xs"
                style={{ color: colorPicker("primary.700") }}
              >
                I have the necessary credentials to access the system and ensure
                compliance with all requirements.
              </Typography>
            </div>
          </div>
          <div className="RightQuestion">
            <ArrowRightOutlined style={{ color: colorPicker("primary.700") }} />
          </div>
        </div>
        <div
          className={`QuestionBox ${
            selectedQuestion === 2 ? "--selected" : ""
          }`}
          onClick={() => {
            setSelectedQuestion(2);
            navigate("/credential/airline/question/finkraft/consent");
          }}
        >
          <div className="LeftQuestion">
            <div className="IconBox">
              <FileOutlined style={{ color: colorPicker("primary.700") }} />
            </div>
            <div className="QuestionMeta">
              <Typography
                variant="h6"
                style={{
                  color: colorPicker(
                    selectedQuestion === 2 ? "primary.800" : "neutral.800"
                  ),
                }}
                weight={500}
              >
                Allow finkraft to do the needful
              </Typography>
              <Typography
                variant="xs"
                style={{
                  color: colorPicker(
                    selectedQuestion === 2 ? "primary.800" : "neutral.700"
                  ),
                }}
              >
                I have the necessary credentials to access the system and ensure
                compliance with all requirements.
              </Typography>
            </div>
          </div>
          <div className="RightQuestion">
            <ArrowRightOutlined style={{ color: colorPicker("primary.700") }} />
          </div>
        </div>
      </div>
    </div>
  );
}
