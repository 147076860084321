import ProtectedRoute from "app/utils/ProtectedRoute";
import { AirlineCredentialFlow } from ".";
import AirlineStart from "./components/AirlineStart";
import AirlineQuestion from "./components/AirlineQuestion";
import AirlineInviteQuestion from "./components/AirlineInviteQuestion";
import AirlineInviteUser from "./components/AirlineInviteUser";
import AirlineFinkraftConsent from "./components/AirlineFinkraftConsent";

//eslint-disable-next-line
export default {
  path: "/credential/airline",
  element: (
    <ProtectedRoute>
      <AirlineCredentialFlow />
    </ProtectedRoute>
  ),
  strict: true,
  children: [
    {
      path: "/credential/airline/start",
      element: <AirlineStart />,
      exact: true,
    },
    {
      path: "/credential/airline/question",
      element: <AirlineQuestion />,
      exact: true,
    },
    {
      path: "/credential/airline/question/invite",
      element: <AirlineInviteQuestion />,
      exact: true,
    },
    {
      path: "/credential/airline/question/invite/user",
      element: <AirlineInviteUser />,
      exact: true,
    },
    {
      path: "/credential/airline/question/finkraft/consent",
      element: <AirlineFinkraftConsent />,
      exact: true,
    },
  ],
};
