import Typography from "app/shared/Typography";
import "./GSTAddReviewFinal.scss";
import { colorPicker } from "app/utils/color.helper";

import {
  Button,
  Checkbox,
  message,
  Segmented,
  Statistic,
  StatisticProps,
  Tag,
  Upload,
} from "antd";
import {
  SwapOutlined,
  DownloadOutlined,
  InfoCircleOutlined,
  ArrowRightOutlined,
  SendOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { AgTableClient } from "app/shared/AgTable";
import { STATE_GSTIN_ICON } from "app/shared/AgTable/stateIconMap";
import Uploader from "app/shared/Uploader";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { API_ENDPOINT_GST_CREDENTIAL_ENTITY_INFO } from "../../gstcredflow.constants";
import { apiPost } from "app/services/apiServices";
import CountUp from "react-countup";
import * as csv from "csvtojson";
import * as XLSX from "xlsx";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import { API_ENDPOINT_GST_CREDENTIAL_BULK_CREATE_NEW } from "app/scenes/Credentials/credentials.constants";
export default function GSTAddReviewFinal(props: any) {
  const location = useLocation();
  const stateInfo = location.state;
  const [activeTab, setActiveTab] = useState("Failed");
  const [gstinData, setGSTINData] = useState<any[]>([]);
  const [activeGSTIN, setActiveGSTIN] = useState(0);
  const [totalPan, setTotalPan] = useState([]);
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  const [isLoading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  console.log("gstinstatre:", stateInfo);
  useEffect(() => {
    setGSTINData(stateInfo);
    fetchEntityInfo(stateInfo);
  }, []);

  const formatter: StatisticProps["formatter"] = (value) => (
    <CountUp end={value as number} separator="," />
  );

  const fetchEntityInfo = async (gstinInfo: any = gstinData) => {
    const response = await apiPost(API_ENDPOINT_GST_CREDENTIAL_ENTITY_INFO, {
      gstins: gstinInfo,
    });

    console.log("response:", response);
    if (response.status) {
      setGSTINData(response.data);
      let totalActive = 0;
      let pans: any = [];
      response.data.forEach((gstInfo: any) => {
        if (gstInfo.entityInfi?.lgnm) {
          totalActive += 1;
        }
        if (gstInfo.gstin.length === 15) {
          let parsedPAN = gstInfo.gstin.substring(2, 12);
          if (!pans.includes(parsedPAN)) {
            pans.push(parsedPAN);
          }
        }
        setTotalPan(pans);
        setActiveGSTIN(totalActive);
      });
    }
  };

  const handleUpload = async (file: any) => {
    if (!file.originFileObj) {
      const reader = new FileReader();

      reader.onload = async (e: any) => {
        const binaryString = e.target.result;
        let jsonArray;

        if (file.type.includes("csv")) {
          //@ts-ignore
          jsonArray = await csv().fromString(binaryString);
        } else if (
          file.type.includes("sheet") ||
          file.type.includes("excel") ||
          file.name.endsWith(".xls") ||
          file.name.endsWith(".xlsx")
        ) {
          const workbook = XLSX.read(binaryString, { type: "binary" });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          jsonArray = XLSX.utils.sheet_to_json(worksheet);
        } else {
          console.error("Unsupported file format");
          return;
        }

        // Trim whitespace from each value in the JSON array
        jsonArray = jsonArray.map((row: any) => {
          return Object.fromEntries(
            Object.entries(row).map(([key, value]) => [
              key,
              typeof value === "string" ? value.trim() : value,
            ])
          );
        });

        setGSTINData([...jsonArray, ...gstinData]);

        console.log("jsonArray", jsonArray);
      };

      reader.readAsBinaryString(file);
    }
  };

  const handleAddGSTCredential = async () => {
    setLoading(true);
    const payload = {
      credentials: gstinData,
    };

    const response = await apiPost(
      API_ENDPOINT_GST_CREDENTIAL_BULK_CREATE_NEW,
      payload
    );
    if (response.status) {
      messageApi.success({
        type: "success",
        content: "Credential added",
      });
      navigate("/credential/gst/add/done");
    } else {
      messageApi.error({
        type: "error",
        content: "Could not add credential",
      });
    }
    setLoading(false);
  };

  return (
    <div className="GSTAddReviewFinal">
      <div className="MainContainer">
        <div className="RightContainer">
          <Tag color="cyan">Last step ahead</Tag>
          <Typography variant="h5" style={{ marginTop: 12 }}>
            Summary of{" "}
            <span style={{ color: colorPicker("primary.700") }}>
              {userDetails?.currentWorkspace?.name}
            </span>
          </Typography>

          <div className="SummaryContainer">
            <div className="SummaryCard">
              <Typography variant="caption">Number of PAN</Typography>
              <Typography
                variant="h4"
                style={{ color: colorPicker("primary.700") }}
                weight={600}
              >
                <Statistic value={totalPan.length} formatter={formatter} />
              </Typography>
            </div>

            <div className="SummaryCard">
              <Typography variant="caption">Total GSTIN</Typography>
              <Typography
                variant="h4"
                style={{ color: colorPicker("primary.700") }}
                weight={600}
              >
                <Statistic value={gstinData.length} formatter={formatter} />
                {/* {gstinData.length} */}
              </Typography>
            </div>

            <div className="SummaryCard">
              <Typography variant="caption">Active</Typography>
              <Typography
                variant="h4"
                style={{ color: colorPicker("primary.700") }}
                weight={600}
              >
                <Statistic value={activeGSTIN} formatter={formatter} />
              </Typography>
            </div>

            <div className="SummaryCard">
              <Typography
                variant="caption"
                style={{ color: colorPicker("red.700") }}
              >
                Failed
              </Typography>
              <Typography
                variant="h4"
                style={{ color: colorPicker("red.700") }}
                weight={600}
              >
                <Statistic
                  value={
                    gstinData.length > activeGSTIN
                      ? gstinData.length - activeGSTIN
                      : 0
                  }
                  formatter={formatter}
                />
              </Typography>
            </div>
          </div>

          <div style={{ marginTop: 12 }}>
            <Segmented
              options={["Failed", "Active"]}
              value={activeTab}
              onChange={(value: any) => {
                console.log(value); // string
              }}
              size="small"
            />
          </div>

          <div className="TableContainer">
            <AgTableClient
              // @ts-ignore
              rowData={gstinData}
              columnDefs={[
                {
                  field: "gstin",
                  headerName: "GSTIN",
                  cellRenderer: (params: any) => {
                    return (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          style={{ width: 24, marginRight: 6 }}
                          src={
                            STATE_GSTIN_ICON[
                              params?.data?.gstin?.substring(0, 2)
                            ]
                          }
                        />
                        {params.data.gstin}
                      </div>
                    );
                  },
                },
                { field: "username", headerName: "Username" },
                { field: "password", headerName: "Password" },
                {
                  field: "entityInfi.lgnm",
                  headerName: "Entity Name",
                  cellRenderer: (params: any) => {
                    return params.data?.entityInfi?.lgnm ? (
                      params.data?.entityInfi.lgnm
                    ) : params.data?.entityInfi &&
                      !params.data?.entityInfi.lgnm ? (
                      <Tag color="red">Not Found</Tag>
                    ) : (
                      <Tag color="orange" icon={<LoadingOutlined />}>
                        Loading
                      </Tag>
                    );
                  },
                },
                {
                  field: "entityInfi.tradeNam",
                  headerName: "Legal Trade Name",
                  cellRenderer: (params: any) => {
                    return params.data?.entityInfi?.tradeNam ? (
                      params.data?.entityInfi.tradeNam
                    ) : params.data?.entityInfi &&
                      !params.data?.entityInfi.tradeNam ? (
                      <Tag color="red">Not Found</Tag>
                    ) : (
                      <Tag color="orange" icon={<LoadingOutlined />}>
                        Loading
                      </Tag>
                    );
                  },
                },
              ]}
              hideToolbar
              autoResize
            />
          </div>
          <div className="UploadContainer">
            <div className="Header">
              <Typography variant="caption">GST Credentials</Typography>
              <Button icon={<DownloadOutlined />} size="small" type="primary">
                Download sample file
              </Button>
            </div>
            <div className="UploaderWidget">
              <Uploader colored />
            </div>
            <div className="UploaderFooter">
              <Checkbox checked>
                Finkraft will download all GST certificates on your behalf.
              </Checkbox>
              <Typography
                variant="caption"
                style={{ color: colorPicker("neutral.700") }}
              >
                <InfoCircleOutlined style={{ marginRight: 4 }} />
                The file should contain GSTIN, Username & Password
              </Typography>
            </div>

            <div className="ActionContainer">
              <Button
                icon={<SendOutlined />}
                style={{ width: "48%" }}
                size="large"
                onClick={() => navigate("/credential/gst/invite/user")}
                loading={isLoading}
              >
                Invite user who has credentials
              </Button>
              <Button
                icon={<ArrowRightOutlined />}
                style={{ width: "48%" }}
                type="primary"
                size="large"
                onClick={handleAddGSTCredential}
                loading={isLoading}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
      {contextHolder}
    </div>
  );
}
