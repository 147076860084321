import Typography from "app/shared/Typography";
import "./GSTAddReview.scss";
import { colorPicker } from "app/utils/color.helper";
import { Carousel } from "react-responsive-carousel";
import AirOnboarIllustration1 from "static/images/illustration/AirOnboardIllustration1.svg";
import AirOnboarIllustration2 from "static/images/illustration/AirOnboardIllustration2.svg";
import AirOnboarIllustration3 from "static/images/illustration/AirOnboardIllustration3.svg";
import ISOCert from "static/images/ISO.svg";
import GDPRCert from "static/images/GDPR.svg";
import SOCCert from "static/images/SOC.svg";
import { Button, Checkbox, Tag } from "antd";
import * as csv from "csvtojson";
import * as XLSX from "xlsx";
import {
  SwapOutlined,
  DownloadOutlined,
  InfoCircleOutlined,
  ArrowRightOutlined,
  SendOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { AgTableClient } from "app/shared/AgTable";
import { STATE_GSTIN_ICON } from "app/shared/AgTable/stateIconMap";
import Uploader from "app/shared/Uploader";
import { useLocation, useNavigate } from "react-router-dom";
import { downloadSampelCrendentialCSV } from "app/utils/generic.helper";
import { apiPost } from "app/services/apiServices";
import { API_ENDPOINT_GST_CREDENTIAL_ENTITY_INFO } from "../../gstcredflow.constants";
import { useEffect, useState } from "react";
export default function GSTAddReview(props: any) {
  const [gstinData, setGSTINData] = useState<any[]>([]);
  const location = useLocation();
  const stateInfo = location.state;
  const navigate = useNavigate();

  useEffect(() => {
    setGSTINData([stateInfo]);
  }, []);

  const handleUpload = async (file: any) => {
    if (!file.originFileObj) {
      const reader = new FileReader();

      reader.onload = async (e: any) => {
        const binaryString = e.target.result;
        let jsonArray;

        if (file.type.includes("csv")) {
          //@ts-ignore
          jsonArray = await csv().fromString(binaryString);
        } else if (
          file.type.includes("sheet") ||
          file.type.includes("excel") ||
          file.name.endsWith(".xls") ||
          file.name.endsWith(".xlsx")
        ) {
          const workbook = XLSX.read(binaryString, { type: "binary" });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          jsonArray = XLSX.utils.sheet_to_json(worksheet);
        } else {
          console.error("Unsupported file format");
          return;
        }

        // Trim whitespace from each value in the JSON array
        jsonArray = jsonArray.map((row: any) => {
          return Object.fromEntries(
            Object.entries(row).map(([key, value]) => [
              key,
              typeof value === "string" ? value.trim() : value,
            ])
          );
        });

        setGSTINData([...jsonArray, ...gstinData]);

        console.log("jsonArray", jsonArray);
      };

      reader.readAsBinaryString(file);
    }
  };

  return (
    <div className="GSTAddReview">
      <div className="MainContainer">
        <div className="LeftContainer">
          <div className="CarouselContainer">
            <Carousel showArrows={false} showThumbs={false} showStatus={false}>
              <div>
                <img
                  src={AirOnboarIllustration1}
                  style={{ width: 288, height: 288 }}
                />
              </div>
              <div>
                <img
                  src={AirOnboarIllustration2}
                  style={{ width: 288, height: 288 }}
                />
              </div>
              <div>
                <img
                  src={AirOnboarIllustration3}
                  style={{ width: 288, height: 288 }}
                />
              </div>
            </Carousel>
          </div>

          <Typography variant="h5" style={{ marginTop: 44 }}>
            500+ companies already saved millions now it’s your turn...
          </Typography>
          <Typography style={{ marginTop: 26 }}>
            Easily track and manage your travel-related expenses with clear,
            vendor-based summaries, optimizing ITC claims and ensuring GST
            compliance.
          </Typography>
          <div className="CompanyContainer">
            <img
              src={
                "https://upload.wikimedia.org/wikipedia/commons/thumb/c/ce/Coca-Cola_logo.svg/1200px-Coca-Cola_logo.svg.png"
              }
              alt="ISO Image"
            />
            <img
              src={"https://media-publications.bcg.com/BCG_MONOGRAM.png"}
              alt="ISO Image"
              style={{ marginLeft: 48, marginRight: 48 }}
            />
            <img
              src={
                "https://www.pngplay.com/wp-content/uploads/13/Bajaj-Auto-PNG-HD-Quality.png"
              }
              alt="ISO Image"
            />
            <img
              src={
                "https://storage.googleapis.com/assets.cdp.blinkx.in/Blinkx_Website/icons/zee-media-corporation-ltd.png"
              }
              alt="ISO Image"
            />

            <img
              src={
                "https://cdn.iconscout.com/icon/free/png-256/free-unilever-logo-icon-download-in-svg-png-gif-file-formats--company-british-uk-logos-icons-2357829.png?f=webp"
              }
              alt="ISO Image"
            />
            <img
              src={
                "https://w7.pngwing.com/pngs/695/521/png-transparent-comic-book-comics-retail-ikea-coupon-others-thumbnail.png"
              }
              alt="ISO Image"
            />
          </div>

          <div className="CertificateContainer">
            <img src={ISOCert} alt="ISO Image" />
            <img
              src={GDPRCert}
              alt="ISO Image"
              style={{ marginLeft: 48, marginRight: 48 }}
            />
            <img src={SOCCert} alt="ISO Image" />
          </div>
        </div>
        <div className="RightContainer">
          <Tag color="cyan">Last step ahead</Tag>
          <Typography variant="h5" style={{ marginTop: 12 }}>
            To find out how much 2 entities can save on
          </Typography>

          <div className="PanGstSelectionContainer">
            <Typography
              variant="caption"
              style={{ color: colorPicker("primary.700") }}
            >
              Entity Name:{" "}
              <span style={{ color: colorPicker("neutral.700") }}>
                Schnider Limited
              </span>
            </Typography>
            <Typography
              variant="caption"
              style={{ color: colorPicker("primary.700"), marginLeft: 24 }}
            >
              PAN:{" "}
              <span style={{ color: colorPicker("neutral.700") }}>
                AA23221PA1
              </span>
            </Typography>
            <SwapOutlined
              style={{ color: colorPicker("primary.700"), marginLeft: 12 }}
            />
          </div>
          <div className="TableContainer">
            <AgTableClient
              // @ts-ignore
              rowData={gstinData}
              columnDefs={[
                {
                  field: "gstin",
                  headerName: "GSTIN",
                  cellRenderer: (params: any) => {
                    return (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          style={{ width: 24, marginRight: 6 }}
                          src={
                            STATE_GSTIN_ICON[params.data.gstin.substring(0, 2)]
                          }
                        />
                        {params.data.gstin}
                      </div>
                    );
                  },
                },
                { field: "username", headerName: "Username" },
                { field: "password", headerName: "Password" },
              ]}
              hideToolbar
              autoResize
            />
          </div>
          <div className="UploadContainer">
            <div className="Header">
              <Typography variant="caption">GST Credentials</Typography>
              <Button
                icon={<DownloadOutlined />}
                size="small"
                type="primary"
                onClick={downloadSampelCrendentialCSV}
              >
                Download sample file
              </Button>
            </div>
            <div className="UploaderWidget">
              <Uploader colored onSelect={handleUpload} />
            </div>
            <div className="UploaderFooter">
              <Checkbox checked>
                Finkraft will download all GST certificates on your behalf.
              </Checkbox>
              <Typography
                variant="caption"
                style={{ color: colorPicker("neutral.700") }}
              >
                <InfoCircleOutlined style={{ marginRight: 4 }} />
                The file should contain GSTIN, Username & Password
              </Typography>
            </div>

            <div className="ActionContainer">
              <Button
                icon={<SendOutlined />}
                style={{ width: "48%" }}
                size="large"
                onClick={() => navigate("/credential/gst/invite/user")}
              >
                Invite user who has credentials
              </Button>
              <Button
                icon={<ArrowRightOutlined />}
                style={{ width: "48%" }}
                type="primary"
                size="large"
                onClick={() =>
                  navigate("/credential/gst/add/review/final", {
                    state: gstinData,
                  })
                }
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
