import Typography from "app/shared/Typography";
import "./GSTAddDone.scss";
import { colorPicker } from "app/utils/color.helper";
import { Carousel } from "react-responsive-carousel";
import AirOnboarIllustration1 from "static/images/illustration/AirOnboardIllustration1.svg";
import doneIllustration from "static/images/doneIllustration.svg";
import { useState } from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
export default function GSTAddDone(props: any) {
  const navigate = useNavigate();
  return (
    <div className="GSTAddDone">
      <div className="MainContainer">
        <div className="SuccessBlockContainer">
          <img src={doneIllustration} alt="doneIllustration" />
          <Typography
            style={{ marginTop: 24, color: colorPicker("neutral.900") }}
            variant="h6"
          >
            GST Integration succesful!
          </Typography>
          <Typography
            style={{
              marginTop: 6,
              color: colorPicker("neutral.900"),
              textAlign: "center",
            }}
            variant="caption"
          >
            Please give us some time to calculate your claimable amount.
          </Typography>
          <Typography
            style={{ color: colorPicker("neutral.800"), marginTop: 24 }}
            variant="xs"
          >
            Help us, to know about your organization
          </Typography>
          <Button
            style={{ marginTop: 24, width: "100%" }}
            type="primary"
            onClick={() => navigate("/credential/gst/landing")}
          >
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
}
