import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  message,
  Select,
  Tag,
} from "antd";
import { apiGet, apiPost } from "app/services/apiServices";
import TextField from "app/shared/TextField";
import Typography from "app/shared/Typography";
import { useState } from "react";
import AppLogo from "static/images/Logo.svg";
import {
  ArrowRightOutlined,
  FileOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { colorPicker } from "app/utils/color.helper";
import "../../../AirlineCredentialFlow/AirlineCredentialFlow.scss";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import { API_ENDPOINT_AIRLINE_CREDENTIAL_INVITE_USER } from "../../gstcredflow.constants";

type FieldType = {
  email?: string;
  password?: string;
};

export default function GSTInviteTax(props: any) {
  const [email, setEmail] = useState("");
  const [userRole, setRole] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [userDetails, _] = useRecoilState<any>(userInfo);
  const handleSendInvite = async () => {
    if (!name || !email || !userRole) {
      messageApi.error({
        type: "error",
        content: "Please add user inforamation",
      });
      return;
    }
    const payload = {
      email: email,
      role: userRole,
      name: name,
      phone: phone,
      workspaces: [userDetails?.currentWorkspace?.id],
    };
    console.log("payload is:", payload);
    const respose = await apiPost(API_ENDPOINT_AIRLINE_CREDENTIAL_INVITE_USER, {
      userList: [payload],
    });
    if (respose.status) {
      messageApi.success({
        type: "success",
        content: "User invited succesfully",
      });
      setName("");
      setPhone("");
      setRole("");
      setEmail("");
    } else {
      messageApi.error({
        type: "error",
        content: "Please add your inforamation",
      });
    }
  };

  return (
    <div className="AirlineStart">
      <Tag color="cyan">GST Integration</Tag>
      <Typography variant="h5" style={{ marginTop: 12 }}>
        Invite user
      </Typography>

      <div className="FormContainer">
        <div className="fieldGroup">
          <Typography
            variant="xs"
            style={{ marginBottom: 2, color: colorPicker("neutral.700") }}
          >
            Name
          </Typography>
          <Input
            placeholder="Name"
            onChange={(e: any) => setName(e.target.value)}
            value={name}
          />
        </div>
        <div className="fieldGroup">
          <Typography
            variant="xs"
            style={{ marginBottom: 2, color: colorPicker("neutral.700") }}
          >
            Email
          </Typography>
          <Input
            placeholder="Email"
            onChange={(e: any) => setEmail(e.target.value)}
            value={email}
          />
        </div>
        <div className="fieldGroup">
          <Typography
            variant="xs"
            style={{ marginBottom: 2, color: colorPicker("neutral.700") }}
          >
            Contact Number
          </Typography>
          <Input
            placeholder="Contact Number"
            onChange={(e: any) => setPhone(e.target.value)}
            value={phone}
          />
        </div>
        <div className="fieldGroup">
          <Typography
            variant="xs"
            style={{ marginBottom: 2, color: colorPicker("neutral.700") }}
          >
            Role
          </Typography>
          <Input
            placeholder="User Role"
            onChange={(e: any) => setRole(e.target.value)}
            value={userRole}
          />
        </div>

        <Button
          style={{ width: "100%", marginTop: 24 }}
          icon={<SendOutlined />}
          size="large"
          type="primary"
          onClick={handleSendInvite}
        >
          Send Invite
        </Button>
      </div>
      {contextHolder}
    </div>
  );
}
