export const downloadSampelCrendentialCSV = async () => {
  let csvString = `gstin,username,password
                    09AABCG3241G1ZL,testuser1,user_password
                    09AABCG3141G1ZL,testuser2,user_password
                    09AABCG8111G1ZL,testuser3,user_password`;
  try {
    // Convert the CSV string to a Blob
    const csvBlob = new Blob([csvString], { type: "text/csv" });

    // Convert the Blob object to a data URL
    const csvDataUrl = URL.createObjectURL(csvBlob);

    // Create a temporary anchor element
    const link = document.createElement("a");
    link.href = csvDataUrl;
    link.setAttribute("download", "sample_credentials.csv");

    // Trigger the download by programmatically clicking the anchor element
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error downloading CSV file:", error);
  }
};
