import PageHeader from "app/shared/PageHeader";
import "./Uploads.scss";
import Uploader from "app/shared/Uploader";

import { useEffect, useState } from "react";
import { uploadInfo, userInfo } from "app/config/States/users";
import GlobalUploader from "app/shared/GlobalUploader";
import { useRecoilState } from "recoil";
import AgTableClient from "app/shared/AgTable/AgTableClient";
import { apiGet } from "app/services/apiServices";
import { Button, Input, Modal, Select, Tag } from "antd";
import {
  PlusOutlined,
  LoadingOutlined,
  CheckCircleFilled,
  DownloadOutlined,
  EyeOutlined,
  CloseCircleFilled,
  EyeFilled,
} from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "app/shared/Loader";
import Typography from "app/shared/Typography";
import { colorPicker } from "app/utils/color.helper";
import moment from "moment";
import {
  API_ENDPOINT_STORAGE_OBJECTS_LOGS,
  API_ENDPOINT_STORAGE_S3_SIGNED_URL,
} from "global.constants";

export default function Uploads(props: any) {
  const [fileUploadInfo, setUploadInfo] = useRecoilState<any>(uploadInfo);
  const [rowData, setRowData] = useState<any[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [showModalFor, setShowModalFor] = useState("");
  const [frequency, setFrequency] = useState("");
  const [dataset, setDataset] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [userDetails, _] = useRecoilState<any>(userInfo);

  const navigate = useNavigate();

  useEffect(() => {
    if (!fileUploadInfo?.file) {
      handleFetchRowData();
    }
  }, [fileUploadInfo, userDetails.currentWorkspace]);
  const handleFetchRowData = async () => {
    setLoading(true);
    const response = await apiGet(API_ENDPOINT_STORAGE_OBJECTS_LOGS);
    if (response.status) {
      setRowData(response.data);
    }
    setLoading(false);
  };

  const handleSelectedFile = (file: any) => {
    setUploadInfo({ file, bucketName: "colmapping", dataset: dataset });
    setShowModal(false);
  };

  const handleOpenCSVFile = async (file: string) => {
    const response = await apiGet(
      API_ENDPOINT_STORAGE_S3_SIGNED_URL + "?url=" + file
    );
    if (response.status) {
      window.open(response.data);
    } else {
    }
  };

  const columnDefs = [
    {
      field: "filename",
      headerName: "File Name",
      enableRowGroup: true,
    },
    {
      field: "dataset_type",
      headerName: "Dataset Type",
      formatType: "CHIP",
      enableRowGroup: true,
    },
    {
      field: "created_at",
      headerName: "Uploaded At",
      formatType: "DATETIME",
      enableRowGroup: true,
    },
    {
      field: "user_info.email",
      headerName: "Uploaded By",
      enableRowGroup: true,
    },
  ];

  return (
    <div className="Uploads">
      <div className="Header">
        <PageHeader
          title="File Upload History"
          rightActions={
            <Button
              type="primary"
              size="small"
              icon={<PlusOutlined />}
              onClick={() => {
                setShowModalFor("UPLOAD");
                setShowModal(true);
              }}
            >
              Upload New File
            </Button>
          }
        />
      </div>

      <div className="ContentContainer">
        {isLoading ? (
          <Loader />
        ) : (
          <AgTableClient
            // @ts-ignore
            rowData={rowData}
            columnDefs={columnDefs}
            pdfDataKey="S3 Link"
            autoResize
            hideToolbar
            // xautoGroupColumnDef={["Vendor Name"]}
          />
        )}
      </div>
      <Modal
        open={showModal}
        title="Upload File"
        footer
        onCancel={() => setShowModal(false)}
        width={700}
      >
        <div style={{ marginTop: 24 }}>
          <Select
            style={{ width: "100%", marginBottom: 24 }}
            size="small"
            placeholder="Select Dataset"
            options={[
              {
                label: "Booking",
                value: "BOOKING",
              },
              {
                label: "Invoice",
                value: "INVOICE",
              },
              {
                label: "2A/2B",
                value: "2A_2B",
              },
            ]}
            onChange={(selectedValue: any) => setDataset(selectedValue)}
          />
          {dataset ? (
            <Uploader onSelect={handleSelectedFile} fileType=" " />
          ) : null}
        </div>
      </Modal>
    </div>
  );
}
